import { useProductService } from '~/services/product';
import { CustomHTTPError } from '@/restAPI/error';

export default defineNuxtRouteMiddleware(async ({ params, query }, from) => {
  const { getProductLite } = useProductService();
  const nuxtApp = useNuxtApp();
  const { showErrorDialog } = useErrorDialog();
  const { product: productId } = params;

  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return undefined;

  if (!productId.includes('-')) {
    try {
      const { url } = await getProductLite(parseInt(productId as string, 10));

      return navigateTo({
        path: url ?? '',
        query,
      }, { redirectCode: 301 });
    } catch (e) {
      if (process.client) {
        if ((e as CustomHTTPError)?.response?.status === 404) {
          showErrorDialog({
            title: 'Не получилось найти товар',
            subtitle: 'Данный товар больше не опубликован на платформе',
          });
        }

        return navigateTo(from.fullPath);
      }
    }
  }

  return undefined;
});
